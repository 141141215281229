
import axios, { AxiosError } from 'axios';
import { defineComponent, ref, onMounted, watch } from 'vue';
import { useRouter } from "vue-router";
import { campHandleCNPJ, campHandleBrPhoneNumber } from '@/composables/DataValidation';
import { useCompaniesStore } from "@/store/CompaniesStore";
import useAlert from "@/composables/Alert";
import {
  CampHeader,
  CampEmptyListFeedbackV2,
  CampTable,
  CampTableTd,
  CampTableTh,
  CampDropdown,
  CampDropdownHeader,
  CampDropdownItem
} from '@/components';
import { Modal } from "bootstrap";

import modalRelationStore from './Components/modalRelationStore.vue';
import modalRelationCompetitor from './Components/modalRelationCompetitor.vue';
import modalRelationCompany from './Components/modalRelationCompany.vue';
import modalRelationProduct from './Components/modalRelationProducts.vue';
import { useLoaderStore} from "@/store/LoaderStore";
import { useStoreStore } from '@/store/StoreStore';
import { useCompanyStore } from '@/store/CompanyStore';
import CampModalDelRegister from '@/components/CampModalDelRegister.vue';
import { useAuthStore } from '@/store/AuthStore';
import SkeletonComponent from '@/components/SkeletonComponent.vue';
import { IStoreData } from './PostPage.vue';
import CampModalRecoverRegister from '@/components/CampModalRecoverRegister.vue';

interface ICompanyResponse {
  data: {
    error: boolean,
    message: string,
    data: IStoreData[]
    errorCode: string
  }
}

export default defineComponent({
  name: "StoreList",
  components: {
    CampHeader,
    CampEmptyListFeedbackV2,
    CampTable,
    CampTableTd,
    CampTableTh,
    modalRelationStore,
    modalRelationCompetitor,
    modalRelationCompany,
    modalRelationProduct,
    CampDropdown,
    CampDropdownHeader,
    CampDropdownItem,
    CampModalDelRegister,
    SkeletonComponent,
    CampModalRecoverRegister
  },
  setup() {
    const storeStore = useStoreStore()
    const companyStore = useCompanyStore()
    // const companyName = ref("")
    const isLoading = ref(true)
    const router = useRouter();
    const relationProps = ref({
      store: false,
      competitor: false,
      company: false,
      product: false
    })
    const relationData = ref(null)
    
    const queryObject = ref<{
      isLoading: boolean,
      noError: boolean,
      data:  null | IStoreData[]
    }>({
      isLoading: false,
      noError: false,
      data: null
    })
    
    const store = ref<IStoreData | null>(null);
    const loaderStore = useLoaderStore()
    const companiesStore = useCompaniesStore()

    let auxModal;

    const openModal = id => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal = new Modal(auxElement);
      auxModal.show();
    };

    const printStores = ref<null | IStoreData[]>(null)
    const keywordSearch = ref("")
    const authStore = useAuthStore()
    const activeStatus = ref(true)

    // function funcDetailRelationDataProduct(data) {
    //   openModal('myModalProduct')
    //   relationProps.value = !relationProps.value
    //   relationData.value = data
    // }

    function funcDetailRelationData(data) {
      openModal('myModalUser')
      relationProps.value.store = !relationProps.value.store
      relationData.value = data
    }
    function funcDetailRelationDataCompetitor(data) {
      openModal('myModalCompetitor')
      relationProps.value.competitor = !relationProps.value.competitor
      relationData.value = data
    }

    function funcDetailRelationDataCompany(data) {
      openModal('myModalCompany')
      relationProps.value.company = !relationProps.value.company
      relationData.value = data
    }

    const companiesIsLoading = ref(false)
    async function handleGetCompanies(id: number | null, onlyInactive = false) {
      // loaderStore.open()
      companiesIsLoading.value = true
      isLoading.value = true
      try {
        let queryParams = ""
        if(typeof id === "number" && id > 0){
          queryParams += `id_store=${id}&`
        }
        if(onlyInactive){
          queryParams += `only_inactive=1&`
        }
        if(queryParams.length > 0){
          queryParams = `?${queryParams.slice(0, -1)}`
        }
        const result: ICompanyResponse = await axios.get(`/api/getStoreList${queryParams}`)
        const { data } = result
        if(!data.error) {
          queryObject.value.data = data.data
          queryObject.value.noError = true
          queryObject.value.data = data.data
          printStores.value = data.data
          storeStore.setStore(data.data)
        }
      } catch (error) {
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
          companiesStore.setCompanies([])
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
      } finally {
        companiesIsLoading.value = false
        isLoading.value = false
      }
    }

    function handleFilterObjectsByKeyword(): boolean {
      if(!queryObject.value.data)
        return true
      printStores.value = queryObject.value.data.filter((item) => {
        for (const key in item) {
          if (key !== 'checked') {
            const propValue = item[key];
            if (typeof propValue === 'string' && propValue.toLowerCase().includes(keywordSearch.value.toLowerCase())) {
              return true;
            }
            if (typeof propValue === 'number' && keywordSearch!.toString() === propValue.toString()) {
              return true;
            }
          }
        }
        return false;
      });
      return false
    }

    watch(() => keywordSearch.value, () => handleFilterObjectsByKeyword())

    onMounted(async () => {
      handleGetCompanies(storeStore.getId)
    })

    watch(() => companyStore.company, () => handleGetCompanies(storeStore.getId))

    watch(() => activeStatus.value, () => {
      if(activeStatus.value)
       handleGetCompanies(storeStore.getId)
      else
       handleGetCompanies(storeStore.getId , true)
    })

    // watch(() => companyStore.id, () => {
    //   isLoading.value = true
    // })

    const { showTimeAlert } = useAlert()
    const itemDel = ref<IStoreData | null>(null)
    const toggleModalDelRegister = ref(false)
    function handleModalDelRegister(item: IStoreData) {
      toggleModalDelRegister.value = !toggleModalDelRegister.value
      itemDel.value = item
    }

    async function handleConfirmItemDel() {
      if(itemDel.value) {
        try {
          loaderStore.open()
          await axios.delete(`/api/deleteStore/${itemDel.value.id}`)
          showTimeAlert(`A loja ${itemDel.value.fantasy_name} foi excluída com sucesso!`)
          if(Array.isArray(printStores.value) && printStores.value.length > 1)
            handleGetCompanies(storeStore.getId)
          else
            itemDel.value = null
            storeStore.setId(null)
            storeStore.setStore(null)
            storeStore.setFantasyName("")
        } catch (error) {
          if(error instanceof AxiosError) {
              showTimeAlert(error.response?.data.message, "error")
          }
        } finally {
          loaderStore.close()
        }
      }
    }
    const toggleModalRecoverRegister = ref(false)
    function handleModalRecoverRegister(item: IStoreData) {
      toggleModalRecoverRegister.value = !toggleModalRecoverRegister.value
      itemDel.value = item
    }

    async function fetchDeletedStores() {
      if(!itemDel.value){
        return
      }
      try{
        loaderStore.open()
        await axios.put(`/api/recoverStore/${itemDel.value.id}`)
        showTimeAlert(`Loja ${itemDel.value.fantasy_name} recuperada com sucesso!`)
        if(Array.isArray(printStores.value) && printStores.value.length > 1)
          handleGetCompanies(storeStore.getId, true)
        else
          activeStatus.value = true
          itemDel.value = null
      }catch(error){
        if(error instanceof AxiosError)
          showTimeAlert(error.response?.data.message, "error")
        else
          showTimeAlert("Algo deu errado!", "error")
      }finally{
        loaderStore.close()
      }
    }

    function redirectToStoreProducts(id: number) {
      loaderStore.open()
      router.push("/lojas/produtos")
      storeStore.setId(id)
    }

    return {
      router,
      keywordSearch,
      printStores,
      queryObject,
      toggleModalDelRegister,
      handleModalDelRegister,
      campHandleCNPJ,
      itemDel,
      handleConfirmItemDel,
      funcDetailRelationData,
      relationData,
      relationProps,
      funcDetailRelationDataCompetitor,
      funcDetailRelationDataCompany,
      redirectToStoreProducts,
      isLoading,
      authStore,
      activeStatus,
      store,
      fetchDeletedStores,
      handleModalRecoverRegister,
      toggleModalRecoverRegister,
      campHandleBrPhoneNumber,
    }
  }
})
