
import { ref, onMounted, onUnmounted, defineEmits, watch, Ref } from "vue";
import { defineComponent } from "vue";
import { boolean } from "yup";
import { useLoaderStore } from "@/store/LoaderStore";
import axios from "axios";
import { Modal } from "bootstrap";


// import Api from "../../services/Api";
// import s3 from '../../services/S3Service'
// import useAlert from "../../composables/Alert";

export default defineComponent({
  name: "uploadPlanilhas",
  emits: [
    "updateArrayNotValid",
    "openDragUpload"
  ],
  props: {
    openClose: Boolean,
    inforStore: Object
  },
  setup(props, {emit}) {

    // const { showTimeAlert } = useAlert();

    const arquivo: Ref<any> = ref(null);
    const buttonDisabled = ref(false);
    const fileInput: Ref<any> = ref(null);
    const marcas: Ref<any> = ref([]);
    const historicoArquivos: Ref<any> = ref([]);
    const dialogVisible = ref(false)
    const loaderStore = useLoaderStore()

    
    
    const openInput = () => fileInput.value.click();

    const uploadFile = async (event: any) => {
      const file = event.target.files[0]
      arquivo.value = file
    };
  
    watch(() => props.openClose, () => {
      dialogVisible.value = true
    })

    emit('openDragUpload', dialogVisible.value)

    function dropHandler(e) {

      let file;
      stopDefault(e)

      if (e.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        [...e.dataTransfer.items].forEach((item, i) => {
          // If dropped items aren't files, reject them
          if (item.kind === "file") {
            file = item.getAsFile();
          }
        });
      } else {
        // Use DataTransfer interface to access the file(s)
        [...e.dataTransfer.files].forEach((fileParam, i) => {
          file = fileParam;
        });
      }

      arquivo.value = file

    }

    function stopDefault(e) {
      // Prevent default behavior (Prevent file from being opened)
      e.stopPropagation();
      e.preventDefault();
    }






    async function cancelExport() {
      clearFile()
      dialogVisible.value = !dialogVisible.value
    }

    function clearFile() {
      arquivo.value = null
    }

    let auxModal;

    const openModal = id => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal = new Modal(auxElement);
      auxModal.show();
    };

    async function sendWorkSheet(e) {
              loaderStore.open()

              const formData = new FormData()
              formData.append("file", arquivo.value);
                  try {
                    const response = await axios.post(`/api/verifyStoreCompetitor/${props.inforStore?.id}`, formData)
                    emit('updateArrayNotValid', response.data.data)
                    openModal('myModalCompetitorNotValidCpf')
                    dialogVisible.value = false
                    
                  } catch (error) {
                  } finally {
                    loaderStore.close()
                    clearFile()
                  }
    }

    function pegarListaDePlanilhas() {

      // s3.listObjects({ Bucket: 'varejo-storage', Delimiter: '/', Prefix: 'AtriaLub/planilhas/atualizacao_filtros/', }, (_, data) => {
      //   data.Contents.sort((a, b) => b.LastModified - a.LastModified)
      //   historicoArquivos.value = data.Contents.slice(0, 10)
      // })
    }

    const successMsg = (msg: String) => {
      // showTimeAlert(msg)
      pegarListaDePlanilhas()
    }

    function formatData(data: Date){

        const dia  = data.getDate().toString()

        const diaF = (dia.length == 1) ? '0'+dia : dia

        const mes  = (data.getMonth()+1).toString() //+1 pois no getMonth Janeiro começa com zero.

        const mesF = (mes.length == 1) ? '0'+mes : mes

        const anoF = data.getFullYear();

    return diaF+"/"+mesF+"/"+anoF;
}



    onMounted(() => {
      pegarListaDePlanilhas()
    })

    return {
    
      dropHandler,
      stopDefault,
      arquivo,
      clearFile,
      sendWorkSheet,
      cancelExport,
      historicoArquivos,
      openInput,
      uploadFile,
      fileInput,
      formatData,
      history,
      dialogVisible,
      buttonDisabled
    }
  },
});
