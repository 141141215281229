import { defineStore } from "pinia";
import { useRoute } from "vue-router";
export interface ICompanyData {
  id: number,
  cnpj: string,
  fantasy_name: string,
  responsible: string,
  email: string,
  telephone: string,
  option?: string
}

export const useCompaniesStore = defineStore("CompaniesStore", {
  state: () => {
    return {
      companies: null as ICompanyData[] | null,
      nameMenu: 'Pontuei'
    }
  },
  actions: {
    setCompanies(data: ICompanyData[] | null) {
      this.companies = data
    },
    setStoreName(name) {
      const route = useRoute()
      if(route.name === 'competitorPut') {
        this.nameMenu = name
      }
    }

  }
});
