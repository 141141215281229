
    import { defineComponent, ref, Ref, watch, defineProps } from "vue";
    import { IUserData } from "@/store/UsersStore";
    import axios from "axios";
    import useAlert from "@/composables/Alert";
    import { isCPFValid, formatCPF } from "@/composables/DataValidation";
    import { Modal } from "bootstrap";
    import { useLoaderStore } from "@/store/LoaderStore";
    import  UploadPlanilhas from "@/views/Stores/Components/UploadPlanilhas.vue"


    export default defineComponent({
        name: "modalRelationCompetitor",
        props:{
            relationData: Object,
            isChangeProps: Boolean
        },
        components: {UploadPlanilhas},
        setup(props) {
            const modalRelationInfo = ref()
            const relationDataInfo = ref<any | never>([])
            const ownRelationInfo = ref()           
            const value1 = ref("")
            const options = ref<any>([])
            const isLoading = ref(false)
            const isLoadingButton = ref(false)
            const isLoadSelect = ref(false)
            const lenghtRealation = ref(0)
            const arrayNotValid = ref<Array<any>>([])
            const excelFile = ref<any>()
            const responseValidateCpf = ref([])
            const dialogVisible = ref(false)
            const loaderStore = useLoaderStore()
            const showCpfValidation = ref(false)

          function handleSuccess(response, file, fileList) {
            excelFile.value = file[0].raw
           }
           

          function updateArrayNotValid(list: any) {
            arrayNotValid.value = list
          }

          function openDragUpload() {
            dialogVisible.value = !dialogVisible.value
          }

          async function functionUploadExcel() {
              loaderStore.open()
              const formData = new FormData()
              arrayNotValid.value = []
              formData.append("file", excelFile.value);
              dialogVisible.value = false
                
                  try {
                    const response = await axios.post(`/api/verifyStoreCompetitor/${ownRelationInfo.value.id}`, formData)
                    arrayNotValid.value = response.data.data
      
                    openModal('myModalCompetitorNotValidCpf')
                  } catch (error) {
                  } finally {
                    loaderStore.close()
                  }
          }

            watch(() => excelFile.value, () => {

            }, {deep:true})
            

            const { showTimeAlert } = useAlert()

            function resetSelectValues() {
                value1.value = ""
                lenghtRealation.value = 0
            }
          
            let auxModal;

            const openModal = id => {
              const auxElement = document.querySelector(`#${id}`);
              auxModal = new Modal(auxElement);
              auxModal.show();
            };

            async function onSubmitFunctionOnValidation(data) {
                isLoading.value = true

                try {
                const arrayRelation: any = []
                arrayNotValid.value.forEach(elem => {
                  if(elem.status === 'warnning') {
                    
                  } else {
                    arrayRelation.push({
                        cpf_competitor: elem.cpf,
                        id_store: ownRelationInfo.value.id
                    })

                  }
                    })
                    const response = await axios.post('/api/postCompetitorStore', arrayRelation)
                    await getRelation()
                    geDataRelation()
                    if(arrayNotValid.value.length > 0) {
                    }
                    resetSelectValues()
                } catch (error) {
                  if(error instanceof Error) {

                    showTimeAlert(error.message, "error")
                  }
                } finally {
                    isLoading.value = false
                    lenghtRealation.value =  relationDataInfo.value.length
                }
            }

            async function onSubmitFunction(data) {
              loaderStore.open()
              const arrayValue = value1.value.split(",")
              const objRequest = {
                id_store: ownRelationInfo.value.id,
                array_cpf: arrayValue
              }

              try {
                const response = await axios.post(`/api/verifyStoreCompetitor/${ownRelationInfo.value.id}`, objRequest)
                arrayNotValid.value = response.data.data
                openModal('myModalCompetitorNotValidCpf')

              } catch (error) {
              } finally {
                loaderStore.close()
              }
            }

            async function getRelation(loading: boolean | undefined = true) {
                if(loading){
                    isLoading.value = true
                }
                isLoadingButton.value = true
                try {
                    const response = await axios.get(`/api/getStoreCompetitor/${ownRelationInfo.value.id}`)
                    relationDataInfo.value = response.data.data


                   
                } catch (error) {
                  if(error instanceof Error) {

                  showTimeAlert(error.message, "error")
                  }
                } finally {
                    isLoading.value = false
                    isLoadingButton.value = false
                    lenghtRealation.value =  relationDataInfo.value.length
                }
            }

            watch(
                () => props.isChangeProps,
                () => {
                    ownRelationInfo.value = props.relationData
                    getRelation()
                    geDataRelation()
                }
            );  



            async function geDataRelation() {
                isLoadSelect.value = true
                options.value = []
                try {
                    const response = await axios.get('/api/getCompetitorList')
                    modalRelationInfo.value = response.data.data
                    modalRelationInfo.value.forEach(element => {       
                        if(!relationDataInfo.value.some(elem => elem.cpf === element.cpf)){
                            options.value.push({
                                value: element.cpf,
                                label: element.name
                            })
                       }    

                    
                });
                isLoadSelect.value = false
                } catch (error) {
                    isLoadSelect.value = false
                    if(error instanceof Error) {

                    showTimeAlert(error.message, "error")
                    }
                }
            }
        
  
            async function deletRelation(cpfCompetitor){
                isLoadingButton.value = true
                try {
                    const response = await axios.delete(`/api/deleteCompetitorStore/${cpfCompetitor}/${ownRelationInfo.value.id}`)
                    getRelation(false)
                    geDataRelation()
                   
                } catch (error) {
                  if(error instanceof Error) {

                  showTimeAlert(error.message, "error")
                  }
                } finally {
                    // isLoadingButton.value = false
                }
            }
      


       
            
            return {
                modalRelationInfo,
                ownRelationInfo,
                value1,
                options,
                onSubmitFunction,
                relationDataInfo,
                resetSelectValues,
                deletRelation,
                isLoading,
                isLoadSelect,
                isLoadingButton,
                lenghtRealation,
                arrayNotValid,
                formatCPF,
                excelFile,
                handleSuccess,
                responseValidateCpf,
                onSubmitFunctionOnValidation,
                openDragUpload,
                dialogVisible,
                functionUploadExcel,
                updateArrayNotValid,
                showCpfValidation
            }
        }

    })

