
    import { defineComponent, ref, Ref, watch, defineProps, onMounted } from "vue";
    import { IUserData } from "@/store/UsersStore";
    import axios from "axios";
    import useAlert from "@/composables/Alert";

    export default defineComponent({
        name: "modalRelationProduct",
        props:{
            relationData: Object,
            isChangeProps: Boolean
        
        },
        setup(props) {
            const modalRelationInfo = ref()
            const relationDataInfo = ref<any | never>([])
            const ownRelationInfo = ref()           
            const value1 = ref([])
            const options = ref<any>([])
            const isLoading = ref(false)
            const isLoadingSku = ref(false)
            const isLoadingButton = ref(false)
            const isLoadingButtonSerach = ref(false)
            const isLoadingButtonAddProduct = ref(false)
            const badRequestShearchProduct = ref(false)
            const isLoadSelect = ref(false)
            const lenghtRealation = ref(0)
            const showListProduct = ref(false)
            const imputProductData = ref({
              isShowFormSkuWithoutProduct: false,
              isLoadingSku: false,
              isConfirmSku: false,
              isShowSku: false,
              internalName: "",
              sku: "",
              name: "",
              category:"",
              point: "",
              img: "",
              internalCode: "",
              description: "",
              order: 0
            })

            const { showTimeAlert } = useAlert()

            function resetFormAddProduct() {
              imputProductData.value.sku = ""
              imputProductData.value.name = ""
              imputProductData.value.category  = ""
              imputProductData.value.point = ""
              imputProductData.value.img = ""
              imputProductData.value.internalCode = ""
              imputProductData.value.description = ""
              imputProductData.value.order = 0

            }

            function resetSelectValues() {
                value1.value = []
                lenghtRealation.value = 0
                imputProductData.value.isShowSku = false
                imputProductData.value.sku = ""
                badRequestShearchProduct.value = false
                showListProduct.value = false
            }

            function showListProductFunc() {
              showListProduct.value = !showListProduct.value
            }
         


            async function onSubmitFunction(data) {
              isLoadingSku.value = true
              imputProductData.value.isLoadingSku = true
              badRequestShearchProduct.value = false
              isLoadingButtonSerach.value = true
                  axios(`/api/getProductViewBySku/${imputProductData.value.sku}/${ownRelationInfo.value.id}`).then(res => {
                  imputProductData.value.category = res.data.data.category.name
                  imputProductData.value.internalName = res.data.data.name
                  imputProductData.value.img = res.data.data.url_media
                  imputProductData.value.point = res.data.data.point
                  imputProductData.value.isConfirmSku = true
                  imputProductData.value.description = res.data.data.description
                  imputProductData.value.isShowFormSkuWithoutProduct = false
                  
                }).catch(err => {
                  imputProductData.value.isShowFormSkuWithoutProduct = true
                  imputProductData.value.isConfirmSku = false
                  imputProductData.value.description = ""
                  imputProductData.value.category = ""
                  if(err.status === 403) {
                    badRequestShearchProduct.value = true
                  }
                }).finally(() => {
                  imputProductData.value.isShowSku = true
                  imputProductData.value.isLoadingSku = false
                  isLoadingSku.value = false
                  isLoadingButtonSerach.value = false
                })
            }

            async function onSubmitFunctionCreateProduct(data) {
              isLoadingButtonAddProduct.value = true

                try {
                  const body = {
                    sku: imputProductData.value.sku,
                    id_store: ownRelationInfo.value.id,
                    name:imputProductData.value.name,
                    points:imputProductData.value.point,
                    order:imputProductData.value.order,
                    internal_code:imputProductData.value.internalCode,
                    id_category: 1,
                    description: imputProductData.value.description
                  }
                  const response = await axios.post('/api/postProductStore', body)
                  getRelation()
                  imputProductData.value.isShowSku = false
                  resetFormAddProduct()

                } catch (error) {
                  if(error instanceof Error) {
                    showTimeAlert(error.message, "error")

                  }
                } finally {
                  isLoadingButtonAddProduct.value = false
                }
            }

            async function getRelation(loading: boolean | undefined = true) {
                if(loading){
                    isLoading.value = true
                }
                isLoadingButton.value = true
                try {
                    const response = await axios.get(`/api/getStoreProduct/${ownRelationInfo.value.id}`)
                    relationDataInfo.value = response.data.data
                   
                } catch (error) {
                  if(error instanceof Error) {

                  showTimeAlert(error.message, "error")
                  }
                } finally {
                    isLoading.value = false
                    isLoadingButton.value = false
                    lenghtRealation.value =  relationDataInfo.value.length
                }
            }

            watch(
                () => props.isChangeProps,
                () => {
                    ownRelationInfo.value = props.relationData
                    getRelation()
                    geDataRelation()
                    resetSelectValues()
                }
            );  

            async function geDataRelation() {
                isLoadSelect.value = true
                options.value = []
                try {
                    const response = await axios.get('/api/getCategory')
                    modalRelationInfo.value = response.data.data
                    modalRelationInfo.value.forEach(element => {       
                        if(!relationDataInfo.value.some(elem => elem.id === element.id)){
                            options.value.push({
                                value: element.id,
                                label: element.name
                            })
                       }    

                    
                });
                isLoadSelect.value = false
                } catch (error) {
                    isLoadSelect.value = false

                }
            }
        
  
            async function deletRelation(sku){
                isLoadingButton.value = true
                try {
                    const response = await axios.delete(`/api/deleteProductStore/${sku}/${ownRelationInfo.value.id}`)
                    getRelation(false)
                    geDataRelation()
                   
                } catch (error) {

                } finally {
                    // isLoadingButton.value = false
                }
            }
      


       
            
            return {
                modalRelationInfo,
                ownRelationInfo,
                value1,
                options,
                onSubmitFunction,
                relationDataInfo,
                resetSelectValues,
                deletRelation,
                isLoading,
                isLoadSelect,
                isLoadingButton,
                lenghtRealation,
                imputProductData,
                showListProduct,
                showListProductFunc,
                onSubmitFunctionCreateProduct,
                isLoadingSku,
                badRequestShearchProduct,
                isLoadingButtonSerach,
                isLoadingButtonAddProduct
            }
        }

    })

